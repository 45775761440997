import { Card, Col, Form, FormLabel, Modal, Row } from "react-bootstrap";
import { Button as BootstrapButton } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "../../../components/Formularios/Buttons/Button";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import SelectInput from "../../../shared/SelectInputs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import ptbrLocale from "@fullcalendar/core/locales/pt-br";
import EventContent from "./Components/ConfiguracaoCalendario/DadosAExibir";
import moment from "moment";
import "moment-timezone";
import Autocomplete, {
  AutocompleteInputChangeReason,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
// Defina o fuso horário globalmente
import { getSessionData } from "../../../utils/storageUtils";

import { toast } from "react-toastify";
import ApiPost from "../../../api/endPoints/usePost";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ApiGet from "../../../api/endPoints/useGet";
import {
  mdiAccountPlusOutline,
  mdiCheckCircle,
  mdiKeyboardReturn,
  mdiTrashCan,
} from "@mdi/js";
import { useNavigate } from "react-router-dom";
import "./Components/style/style.css";
import ApiPut from "../../../api/endPoints/usePut";
// import Icon from "@mdi/react";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../api/endPoints/useDelete";
import { TextoFormatoCaptalize } from "../../../components/Auxiliar/ConvTextoCaptalize";
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import DatalistInput from "react-datalist-input";
import { FormPreCadastro } from "../../cadastro/pacientes/precadastropaciente/components/formulario";
import {
  mascaraCNPJ,
  mascaraCPF,
  mascaraTelefoneCelular,
  mascaraTelefoneFixo,
} from "../../../hooks/mascaras";
import { TextoFormatoLowerCase } from "../../../components/Auxiliar/ConvTextoLower";
import axios from "axios";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Api } from "../../../api/api";
moment.tz.setDefault("America/Sao_Paulo");

export function CalendarioConsultas() {
  const navigate = useNavigate();
  const id_clinica = getSessionData("DadosIdClinica") || "";
  const token = getSessionData("MultClinicaWebToken") || "";

  const usuario = getSessionData("DadosUsuario") || "";
  const dadosSalaAtendimento = getSessionData("DadosSalaAtendimento") || "";
  const dadosSalaAtendimentoJson = JSON.parse(dadosSalaAtendimento);

  //CONFIGURAÇÃO DA AGENDA DE ACORDO COM A SALA SELECIONADA
  const configuracaoAgenda = {
    id_sala: dadosSalaAtendimentoJson.id_sala,
    sala: dadosSalaAtendimentoJson.sala,
    tiposala: dadosSalaAtendimentoJson.tiposala,
    desc_tiposala: dadosSalaAtendimentoJson.desc_tiposala,
    id_profissional: dadosSalaAtendimentoJson.id_profissional,
    nome: dadosSalaAtendimentoJson.nome,
    inicio: dadosSalaAtendimentoJson.inicio,
    fim: dadosSalaAtendimentoJson.fim,
    duracao_janela: dadosSalaAtendimentoJson.duracao_janela,
  };

  const dadosTipoEvento = [
    { value: "1000", label: "Agendamento" },
    { value: "1001", label: "Retorno" },
    { value: "1002", label: "Bloqueio" },
  ];

  const dadosTipoConsulta = [
    { value: "0", label: "Particular" },
    { value: "1", label: "Convênio" },
    { value: "2", label: "Cortesia" },
    { value: "4", label: "Bloqueio" }
  ];

  // Função para abrir o modal e definir o evento selecionado
  const [abrirmodal, setAbrirModal] = useState(false);
  // const [selectedEvent, setSelectedEvent] = useState(null);
  const [tipo, setTipo] = useState<string>("");
  const [tipoEvento, setTipoEvento] = useState<string>("");
  const [tipoProcedimento, setTipoProcedimento] = useState<string>("");
  const [tabela, setTabela] = useState<string>("");
  const [dataInicio, setDataInicio] = useState<string>("");
  const [horaInicio, setHoraInicio] = useState<string>("");
  const [dataFim, setDataFim] = useState<string>("");
  const [horaFim, setHoraFim] = useState<string>("");
  const [idAgenda, setIdAgenda] = useState<number>(0);
  const [obs, setObs] = useState<string>("");
  const [faturado, setFaturado] = useState<string>("");
  const [convenio, setConvenio] = useState<string>("");
  // c:onsole.log("convenio: ", convenio);
  const [descConvenio, setDescConvenio] = useState<string>("");
  // console.log("descConvenio: ", descConvenio);

  // const [descontoNum, setDescontoNum] = useState<string>("");

  const descontoR = "R";
  const descontoP = "P";
  const [tipo_Desconto, setTipo_Desconto] = useState<string>(descontoR);
  const [valor, setValor] = useState<number>(0);
  const [desconto, setDesconto] = useState<number>(0);
  const [valorFinal, setValorFinal] = useState<number>(0);
  const [ativaProcedimentos, setAtivaProcedimentos] = useState<boolean>(true);
  const [permiteDesconto, setPermiteDesconto] = useState<boolean>(true);

  //DADOS DO EVENTO
  const [idpaciente, setIdPaciente] = useState<string>("");
  const [paciente, setPaciente] = useState<string>("");

  // DADOS PRE CADASTRO PACIENTE
  const [abrirModalPaciente, setAbrirModalPaciente] = useState<boolean>(false);
  const [preCadastroCPF, setPreCadastroCPF] = useState<string>("");
  const [preCadastroNome, setPreCadastroNome] = useState<string>("");
  const [preCadastroEmail, setPreCadastroEmail] = useState<string>("");
  const [preCadastroTelefone, setPreCadastroTelefone] = useState<string>("");
  const [preCadastroCelular, setPreCadastroCelular] = useState<string>("");

  interface Event {
    id: number;
    title: string;
    start: string;
    end: string;
    // backgroundColor: string;
    // borderColor: string;
    // textColor: string;
    // subtitle: string;
    // detail: string;
  }

  const [eventoSala, setEventoSala] = useState<Event[]>([]);

  const selecionarHorario = (selectInfo: any) => {
    const { start } = selectInfo

    if (criar == true) {

      // setAbrirModal(true);
      const { start, end } = selectInfo;

      const dataInicioOriginal = moment(start);

      const dataInicio = dataInicioOriginal.format("YYYY-MM-DD");
      const horaInicioOriginal = moment.utc(start);
      const horaInicio = horaInicioOriginal.format("HH:mm:ss");

      const dataFinalOriginal = moment(end);
      const dataFinal = dataFinalOriginal.format("YYYY-MM-DD");

      const horaFinalOriginal = moment.utc(end);
      const horaFinal = horaFinalOriginal.format("HH:mm:ss");

      setDataInicio(dataInicio);
      setHoraInicio(horaInicio);
      setStartTime(horaInicio)

      setDataFim(dataFinal);
      setHoraFim(horaFinal);
      setEndTime(horaFinal)
      consultarTipoAtendimento();
      consultarTabelaTuss();
      setIdPaciente("");

      setObs("");
      setTipo("");
      setTabela("");
      setDadosProcedimentos([]);
      setTipoProcedimento("");
      setAtivaProcedimentos(true);
      setPermiteDesconto(true);
      setValor(0);
      setValorFinal(0);
      setDesconto(0);
      setTipo_Desconto(descontoR);
      setTipoEvento("1000");
      setAbrirModal(true);
      setDadosConvenio([]);
      setConvenio("");
      setFaturado("");
    }
    else {

      toast.error("Perfil sem permissão para criar Consultas.")
    }

  };
  function formatarDataHora(data: any) {
    const ano = data.getFullYear();
    const mes = data.getMonth() + 1;
    const dia = data.getDate();
    const horas = data.getHours();
    const minutos = data.getMinutes();
    const segundos = data.getSeconds();

    return `${ano}-${mes < 10 ? "0" + mes : mes}-${dia < 10 ? "0" + dia : dia
      }T${horas < 10 ? "0" + horas : horas}:${minutos < 10 ? "0" + minutos : minutos
      }:${segundos < 10 ? "0" + segundos : segundos}+00:00`;
  }
  const buscarAgendamentosSala = (id_clinica: string, id_sala: string) => {
    setLoading(true);
    setTextoLoading("Carregando Agendamentos da Sala...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/consulta/listar/agendamento/:id_clinica/:id_sala",
      parametros: {
        id_clinica: id_clinica,
        id_sala: id_sala,
      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        const dadosEvento = retorno.map((item: any) => {
          const dataInicio = moment(item.data_inicio).format("YYYY-MM-DD");

          const dataFim = moment(item.data_fim).format("YYYY-MM-DD");

          const inicioCompleto = new Date(`${dataInicio} ${item.hora_inicio}`);
          // Obter componentes individuais da data e hora

          // Formatar os componentes conforme necessário
          const dataInicial = formatarDataHora(inicioCompleto);

          const fimCompleto = new Date(`${dataFim} ${item.hora_fim}`);
          const dataFinal = formatarDataHora(fimCompleto);

          return {
            id: item.id_agendamento,
            title:
              item.tipoevento === "1002" ? "Sala Bloqueada" : item.tiposala,
            start: dataInicial,
            end: dataFinal,
            backgroundColor: item.tipoevento !== "1002" ? "#1D2934" : "#630202",
            borderColor: item.tipoevento !== "1002" ? "#1D2934" : "#630202",
            textColor: "#fff",
            subtitle: item.tipoevento !== "1002" && item.paciente,
            detail:
              item.tipoevento !== "1002"
                ? "Tipo de Atendimento: " +
                item.tipoatendimento +
                " \nObservação: " +
                item.observacao
                : "\nObservação: " + item.observacao,
          };
        });

        setEventoSala(dadosEvento);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  useEffect(() => {
    buscarAgendamentosSala(id_clinica, configuracaoAgenda.id_sala);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_clinica, configuracaoAgenda.id_sala]);

  const [loading, setLoading] = useState<boolean>(false);
  const [textoLoading, setTextoLoading] = useState<string>("");
  const [dadosPaciente, setDadosPacientes] = useState<any[]>([]);
  const carregarPacientes = (id_clinica: string, nome: string) => {
    setLoading(true);
    setTextoLoading("Carregando dados Paciente...");
    const dados = {
      id_clinica,
      nome,
    };
    ApiPost("/paciente/pesquisar/agendamento/paciente", dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDadosPacientes(retorno);
        setShowOptions(true);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const buscarPaciente = (filtro: any) => {
    let pesquisarPaciente = filtro.target.value;
    if (pesquisarPaciente.length >= 3) {
      carregarPacientes(id_clinica, pesquisarPaciente);
    }
  };

  const [dadosConvenio, setDadosConvenio] = useState<any[]>([]);
  const carregarConvenio = (id_clinica: string, filtro: string) => {
    setLoading(true);
    setTextoLoading("Carregando dados Convenio...");
    const dados = {
      id_clinica,
      filtro,
    };
    ApiPost("/convenio/pesquisar/agendamento/convenio", dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDadosConvenio(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const buscarConvenio = (filtro: any) => {
    let pesquisarConvenio = filtro.target.value;
    if (pesquisarConvenio.length >= 3) {
      carregarConvenio(id_clinica, pesquisarConvenio);
    }
  };

  const [inputValue, setInputValue] = useState<string>("");
  // const [inputValueConvenio, setInputValueConvenio] = useState<string>("");

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const capturarPaciente = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setInputValue(value);
    if (value.length >= 3) {
      carregarPacientes(id_clinica, value);
    } else {
      setShowOptions(false);
    }
  };

  const capturarItemSelecionado = (event: React.SyntheticEvent, value: any) => {
    if (value) {
      setShowOptions(false);
      setDadosPacientes([]);
      setIdPaciente(value.id_paciente);
    } else {
      setIdPaciente("");
      setShowOptions(false);
      setDadosPacientes([]);
    }
  };

  const [dadosTipoAtendimento, setDadosTipoAtendimento] = useState<any[]>([]);
  const consultarTipoAtendimento = () => {
    setLoading(true);
    setTextoLoading("Carregando dados Tipo Atendimento...");
    const dados = {
      url: "/codigosistema/listar/TIPOPROCEDIMENTO",
      parametros: {},
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDadosTipoAtendimento(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  const [dadosTabelaTuss, setDadosTabelaTuss] = useState<any[]>([]);
  const consultarTabelaTuss = () => {
    setLoading(true);
    setTextoLoading("Carregando dados Tabela TUSS...");
    const dados = {
      url: "/codigosistema/listar/CODIGOTUSS",
      parametros: {},
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        setDadosTabelaTuss(retorno);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };

  const [dadosProcedimentos, setDadosProcedimentos] = useState<any[]>([]);
  const buscarProcedimentos = (
    id_clinica: string,
    id_profissional: string,
    codigo: string
  ) => {
    setLoading(true);
    setTextoLoading("Carregando Procedimentos...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/agenda/listar/procedimentos/:id_clinica/:id_profissional/:codigo/:isconvenio/:id_convenio",
      parametros: {
        id_clinica: id_clinica,
        id_profissional: id_profissional,
        codigo: codigo,
        isconvenio: faturado != "" ? faturado : "0",
        id_convenio: convenio != "" ? convenio : "0"
      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        if (retorno.length > 0) {

          setDadosProcedimentos(retorno);
          setAtivaProcedimentos(false);
        } else {
          setAtivaProcedimentos(true);
          setDadosProcedimentos([]);
        }
      })
      .catch((erro) => {
        setLoading(false);
        setAtivaProcedimentos(true);
        setDadosProcedimentos([]);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  const salvar = async (
    id_clinica: string,
    id_sala: string,
    idprofissional: string,
    idtiposala: string,
    datainicio: string,
    horainicio: string,
    datafim: string,
    horafim: string,
    id_paciente: string,
    tipoconsulta: string,
    observacao: string,
    usuario: string,
    tabela: string,
    tipoevento: string,
    faturado: string,
    id_procedimento: string,
    valor: string,
    tipo_desconto: string,
    desconto: string,
    valor_final: string,
    id_convenio: string
  ) => {
    setLoading(true);
    setTextoLoading("Criando Agendamento...");
    const dados = {
      id_clinica,
      id_sala,
      idprofissional,
      idtiposala,
      datainicio,
      horainicio,
      datafim,
      horafim,
      id_paciente,
      tipoconsulta,
      observacao: TextoFormatoCaptalize(observacao.toLocaleLowerCase()),
      usuario,
      tabela,
      tipoevento,
      faturado,
      id_procedimento: tipoProcedimento,
      valor,
      tipo_desconto,
      desconto,
      valor_final,
      id_convenio
    };

    setLoading(false)

    ApiPost("/consulta/criar/agendamento", dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        buscarAgendamentosSala(id_clinica, configuracaoAgenda.id_sala);
        toast.success("Agendamento criado com sucesso!");
        if (selectedDates.length !== 0) {
          gravarAgendamentoRecorrente()
        }
        fecharModalAgendamento()
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao salvar dados. Motivo: ${msg}`);
      });
  };

  const gravarAgendamento = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    salvar(
      id_clinica,
      configuracaoAgenda.id_sala,
      configuracaoAgenda.id_profissional,
      configuracaoAgenda.tiposala,
      dataInicio,
      horaInicio,
      dataFim,
      horaFim,
      idpaciente,
      tipo,
      obs,
      usuario,
      tabela,
      tipoEvento,
      faturado,
      tipoProcedimento,
      valor.toString(),
      tipo_Desconto,
      desconto.toString(),
      valorFinal.toString(),
      convenio,
    );
  };

  const dadosAgendamentoRecorrente = [{
    id_clinica,
    id_sala: configuracaoAgenda.id_sala,
    idprofissional: configuracaoAgenda.id_profissional,
    idtiposala: configuracaoAgenda.tiposala,
    id_paciente: idpaciente,
    tipoconsulta: tipo,
    observacao: TextoFormatoCaptalize(obs.toLocaleLowerCase()),
    usuario,
    tabela,
    tipoEvento,
    faturado,
    id_procedimento: tipoProcedimento,
    valor: valor.toString(),
    tipo_desconto: tipo_Desconto,
    desconto: desconto.toString(),
    valorFinal: valorFinal.toString(),
    id_convenio: convenio
  }
  ]

  function mapDiaParaNumero(day: string) {
    const diasMapeados: any = {
      "domingo": 1,
      "segunda-feira": 2,
      "terça-feira": 3,
      "quarta-feira": 4,
      "quinta-feira": 5,
      "sexta-feira": 6,
      "sábado": 7
    };

    return diasMapeados[day.toLowerCase()] || '';
  }

  async function gravarAgendamentoRecorrente() {

    const transformSelectedDates = selectedDates.map((item: any) => {
      let dia = item.diaSemana
      let numeroDia = mapDiaParaNumero(dia)

      return {
        ...item,
        diaSemana: numeroDia
      }
    })
    await axios.post(`${Api}/gravar/agendamento/recorrente`, {
      dadosAgendamentoRecorrente,
      selectedDates: transformSelectedDates,
    }, {
      headers: {
        Authorization: token
      }
    }).then(function (resposta) {
      toast.success(resposta.data.message)
      buscarAgendamentosSala(id_clinica, configuracaoAgenda.id_sala);
      fecharModalAgendamento()
    }).catch(function (erro) {
      toast.error(erro.response.data.message, {
        autoClose: 4000
      })
      fecharModalAgendamento()
    })
  }

  const fecharModalAgendamento = () => {
    setAbrirModal(false);
    setIdPaciente("");
    setDataInicio("");
    setHoraInicio("");
    setDataFim("");
    setHoraFim("");
    setObs("");
    setTipoEvento("1000");
    setTipo("");
    setTabela("");
    setDadosProcedimentos([]);
    setTipoProcedimento("");
    setAtivaProcedimentos(true);
    setPermiteDesconto(true);
    setValor(0);
    setValorFinal(0);
    setTipo_Desconto(descontoR);
    setFaturado("");
    setDadosConvenio([]);
    setConvenio("");
  };

  const [abrirModalEditar, setAbrirModalEditar] = useState<boolean>(false);
  const carregarAgendamento = (
    id_clinica: string,
    id_sala: string,
    id_agendamento: string
  ) => {
    setLoading(true);
    setTextoLoading("Carregando dados de Agendamento...");
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/consulta/listar/agendamento/carregardados/:id_clinica/:id_sala/:id_agendamento",
      parametros: {
        id_clinica,
        id_sala,
        id_agendamento,
      },
      headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        consultarTipoAtendimento();
        consultarTabelaTuss();

        const dadosAgendamento = retorno[0];
        // console.log("dadosAgendamento: ", dadosAgendamento);
        setPaciente(dadosAgendamento.paciente);
        setObs(dadosAgendamento.observacao);
        setDataInicio(
          moment(dadosAgendamento.data_inicio).format("YYYY-MM-DD")
        );
        setDataFim(moment(dadosAgendamento.data_fim).format("YYYY-MM-DD"));
        setHoraInicio(dadosAgendamento.hora_inicio);
        setHoraFim(dadosAgendamento.hora_fim);
        setTipo(dadosAgendamento.id_tipo_atendimento);
        setTabela(dadosAgendamento.codigo_tuss);
        setTipoEvento(dadosAgendamento.tipoevento);
        setFaturado(dadosAgendamento.faturado);
        setValor(dadosAgendamento.valor);
        setTipo_Desconto(dadosAgendamento.tipo_desconto);


        buscarProcedimentos(
          id_clinica,
          configuracaoAgenda.id_profissional,
          dadosAgendamento.id_tipo_atendimento
        );



        setTipoProcedimento(dadosAgendamento.id_procedimento);
        setDesconto(dadosAgendamento.desconto);
        setValorFinal(dadosAgendamento.valor_final);
        setPermiteDesconto(
          dadosAgendamento.aceita_desconto === 1 ? false : true
        );
        setConvenio(dadosAgendamento.id_convenio);
        setDescConvenio(dadosAgendamento.nomeconvenio);

        setAbrirModalEditar(true);
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        toast.error(`Erro ao listar dados. Motivo: ${erro.message}`);
      });
  };
  const editarEvento = (info: any) => {

    if (visualizar == true) {
      const idagendamento = info.event["_def"].publicId;
      setIdAgenda(idagendamento);
      carregarAgendamento(id_clinica, configuracaoAgenda.id_sala, idagendamento);
    }
    else {

      toast.error("Perfil sem permissão para visualizar Consultas.")
    }

  };


  const fecharModalEditarAgendamento = () => {
    setAbrirModalEditar(false);
    setIdPaciente("");
    setDataInicio("");
    setHoraInicio("");
    setDataFim("");
    setHoraFim("");
    setObs("");
    setTipo("");
    setTabela("");
    setTipoEvento("1000");
    setIdAgenda(0);
    setDesconto(0);
    setDadosProcedimentos([]);
    setTipoProcedimento("");
    setAtivaProcedimentos(true);
    setPermiteDesconto(true);
    setValor(0);
    setValorFinal(0);
    setTipo_Desconto(descontoR);
    setFaturado("");
    setDadosConvenio([]);
    setConvenio("");
  };
  const editar = async (
    id_agendamento: number,
    id_clinica: string,
    id_sala: string,
    id_profissional: string,
    id_tiposala: string,
    datainicio: string,
    horainicio: string,
    datafim: string,
    horafim: string,
    tipoconsulta: string,
    observacao: string,
    usuario: string,
    tabela: string,
    tipoevento: string,
    faturado: string,
    id_procedimento: string,
    valor: string,
    tipo_desconto: string,
    desconto: string,
    valor_final: string,
    id_convenio: string,
    selectedDates?: string[]
  ) => {
    setLoading(true);
    setTextoLoading("Alterando Agendamento...");
    const dados = {
      datainicio,
      horainicio,
      datafim,
      horafim,
      tipoconsulta,
      observacao: TextoFormatoCaptalize(observacao.toLocaleLowerCase()),
      usuario,
      tabela,
      tipoevento,
      faturado,
      id_procedimento,
      valor,
      tipo_desconto,
      desconto,
      valor_final,
      id_convenio,
      selectedDates
    };
    ApiPut(
      `/consulta/atualizar/agendamento/${id_agendamento}/${id_clinica}/${id_sala}/${id_profissional}/${id_tiposala}`,
      dados
    )
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        fecharModalEditarAgendamento();
        buscarAgendamentosSala(id_clinica, configuracaoAgenda.id_sala);
        toast.success("Agendamento alterado com sucesso!");
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  const editarAgendamento = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (editar_consulta == true) {

      editar(
        idAgenda,
        id_clinica,
        configuracaoAgenda.id_sala,
        configuracaoAgenda.id_profissional,
        configuracaoAgenda.tiposala,
        dataInicio,
        horaInicio,
        dataFim,
        horaFim,
        tipo,
        obs,
        usuario,
        tabela,
        tipoEvento,
        faturado,
        tipoProcedimento,
        valor.toString(),
        tipo_Desconto,
        desconto.toString(),
        valorFinal.toString(),
        convenio,
        // selectedDates
      );
    }
    else {

      toast.error("Perfil sem permissão para Editar Consulta")
    }
  };

  const editarArrastar = async (
    id_agendamento: number,
    id_clinica: string,
    id_sala: string,
    id_profissional: string,
    id_tiposala: string,
    datainicio: string,
    horainicio: string,
    datafim: string,
    horafim: string,
    usuario: string
  ) => {
    setLoading(true);
    setTextoLoading("Alterando Agendamento...");
    const dados = {
      datainicio,
      horainicio,
      datafim,
      horafim,
      usuario,
    };
    ApiPut(
      `/consulta/atualizar/agendamento/mover/${id_agendamento}/${id_clinica}/${id_sala}/${id_profissional}/${id_tiposala}`,
      dados
    )
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        buscarAgendamentosSala(id_clinica, configuracaoAgenda.id_sala);
        toast.success("Agendamento alterado com sucesso!");
      })
      .catch((erro) => {
        setLoading(false);
        setTextoLoading("");
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
        buscarAgendamentosSala(id_clinica, configuracaoAgenda.id_sala);
      });
  };

  const eventoArrastar = (info: any) => {
    const { start, end } = info.event;

    const dataInicioOriginal = moment(start);

    const dataInicio = dataInicioOriginal.format("YYYY-MM-DD");
    const horaInicioOriginal = moment.utc(start);
    const horaInicio = horaInicioOriginal.format("HH:mm:ss");

    const dataFinalOriginal = moment(end);
    const dataFinal = dataFinalOriginal.format("YYYY-MM-DD");

    const horaFinalOriginal = moment.utc(end);
    const horaFinal = horaFinalOriginal.format("HH:mm:ss");
    editarArrastar(
      info.event["_def"].publicId,
      id_clinica,
      configuracaoAgenda.id_sala,
      configuracaoAgenda.id_profissional,
      configuracaoAgenda.tiposala,
      dataInicio,
      horaInicio,
      dataFinal,
      horaFinal,
      usuario
    );
  };

  // const handleEventMouseEnter = (info: any) => {
  //   // Lógica a ser executada quando o cursor entra no evento
  //   console.log("Mouse sobre o evento:", info.event);
  // };

  // const handleEventMouseLeave = (info: any) => {
  //   // Lógica a ser executada quando o cursor sai do evento
  //   console.log("Mouse fora do evento:", info.event);
  // };

  const excluir = async (
    id_agenda: any,
    id_clinica: string,
    id_sala: string
  ) => {
    await showConfirmationDialog(
      "Excluir Agendamento",
      `Tem certeza que deseja excluir este agendamento?`,
      "question",
      "Excluir",
      "Cancelar"
    )
      .then((result) => {
        if (result.confirmed) {
          setLoading(true);
          setTextoLoading("Excluindo Agendamento...");
          const dados = {
            url: "/consulta/excluir/agendamento/:id_agendamento/:id_clinica/:id_sala",
            parametros: {
              id_agendamento: id_agenda,
              id_clinica: id_clinica,
              id_sala: id_sala,
            },
          };

          ApiDelete(dados)
            .then((retorno) => {
              setLoading(false);
              setTextoLoading("");
              setAbrirModalEditar(false);
              toast.success(retorno.message);
              buscarAgendamentosSala(id_clinica, configuracaoAgenda.id_sala);
            })
            .catch((erro) => {
              setLoading(false);
              setTextoLoading("");
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  const excluirAgendamento = () => {

    if (excluir_consulta == true) {
      excluir(idAgenda, id_clinica, configuracaoAgenda.id_sala);
    }
    else {
      toast.error("Perfil sem permissão para excluir uma Consulta.")
    }
  };

  const selecionaTipoAtendimento = (e: any) => {
    setTipo(e);
    if (e) {
      buscarProcedimentos(
        id_clinica,
        configuracaoAgenda.id_profissional,
        e
      );
    } else {
      setAtivaProcedimentos(true);
      setDadosProcedimentos([]);
    }
  };

  const selecionaProcedimento = (event: any) => {


    if (!event) {
      setValor(0);
      setTipoProcedimento("");
      setPermiteDesconto(true);
      setDesconto(0);
      setValorFinal(0);
      calcularValorFinal(0, tipo_Desconto, desconto || 0);
    } else {

      const opcaoSelecionada = dadosProcedimentos.find(
        (option) => option.value === event
      );
      if (opcaoSelecionada === undefined) {
        setValor(0);
        setTipoProcedimento("");
        setPermiteDesconto(true);
        setDesconto(0);
        setValorFinal(0);
        calcularValorFinal(0, tipo_Desconto, desconto || 0);
      } else {
        setTipoProcedimento(opcaoSelecionada.value);
        handleValorChange(opcaoSelecionada.valor);
        // console.log("opcaoSelecionada.valor: ", opcaoSelecionada.valor);
        setPermiteDesconto(
          opcaoSelecionada.aceita_desconto === 1 ? false : true
        );
        if (opcaoSelecionada.aceita_desconto === 0) {
          setDesconto(0);
          calcularValorFinal(opcaoSelecionada.valor, tipo_Desconto, 0);
        }
      }
    }
  };
  const handleValorChange = (valor: string) => {
    const novoValor = parseFloat(valor || "0");
    setValor(novoValor);
    calcularValorFinal(novoValor, tipo_Desconto, desconto || 0);
  };

  const handleTipoDescontoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const novoTipoDesconto = event.target.value;
    setTipo_Desconto(novoTipoDesconto);
    setDesconto(0); // Reset desconto when switching discount type
    calcularValorFinal(valor || 0, novoTipoDesconto, 0); // Recalculate final value with 0 discount
  };

  const handleDescontoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const novoDesconto = parseFloat(event.target.value || "0");
    setDesconto(novoDesconto);
    calcularValorFinal(valor || 0, tipo_Desconto, novoDesconto);
  };

  const calcularValorFinal = (
    valor: number,
    tipoDesconto: string,
    desconto: number
  ) => {
    let novoValorFinal = 0;

    if (valor === 0) {
      setValorFinal(0);
      return;
    } else {
      if (tipoDesconto === "R") {
        novoValorFinal = valor - desconto;
      } else if (tipoDesconto === "P") {
        novoValorFinal = valor - (valor * desconto) / 100;
      }
    }

    setValorFinal(novoValorFinal);
  };
  const gravarPaciente = (
    id_clinica: string,
    cpf: string,
    nome: string,
    email: string,
    fone: string,
    celular: string,
    usuario: string
  ) => {
    setLoading(true);
    setTextoLoading("Gravando dados do pré cadastro do Paciente");
    const dados = {
      id_clinica,
      cpf,
      nome: TextoFormatoCaptalize(nome.toLowerCase()),
      email: TextoFormatoLowerCase(email.toLowerCase()),
      fone,
      celular,
      usuario_cadastro: usuario,
    };
    ApiPost("/paciente/criar/precadastro", dados)
      .then((retorno) => {
        setLoading(false);
        setTextoLoading("");
        toast.success("Pré Cadastro de Paciente realizado com sucesso!");
        showConfirmationDialog(
          "Enviar Email de Pré Cadastro",
          `Deseja enviar o E-mail de  pré cadastro do paciente \n ${TextoFormatoCaptalize(
            nome.toLowerCase()
          )}?`,
          "question",
          "Sim",
          "Não"
        )
          .then((result) => {
            if (result.confirmed) {
              setLoading(true);
              setTextoLoading("Enviando E-mail de Pré Cadastro");
              const dados = {
                id_clinica,
                nome: TextoFormatoCaptalize(nome.toLowerCase()),
                email: TextoFormatoLowerCase(email.toLowerCase()),
                cpf,
              };
              ApiPost("/paciente/criar/precadastro/sendmail", dados)
                .then((retorno) => {
                  setLoading(false);
                  toast.success(retorno.message);
                  fecharModalPreCadastroPaciente();
                })
                .catch((erro) => {
                  const msg =
                    erro.response?.data.message !== undefined
                      ? erro.response?.data.message
                      : erro.message;

                  showConfirmationDialog(
                    "Falha ao Enviar Email de Pré Cadastro",
                    `Deseja enviar o E-mail de  pré cadastro do paciente \n ${TextoFormatoCaptalize(
                      nome.toLowerCase()
                    )}. Motivo: ${msg}.\n Deseja tentar novamente?`,
                    "error",
                    "Sim",
                    "Não"
                  )
                    .then((result) => {
                      if (result.confirmed) {
                        ApiPost("/paciente/criar/precadastro/sendmail", dados)
                          .then((retorno) => {
                            setLoading(false);
                            toast.success(retorno.message);
                            fecharModalPreCadastroPaciente();
                          })
                          .catch((erro) => {
                            setLoading(false);
                            setTextoLoading("");
                            const msg =
                              erro.response?.data.message !== undefined
                                ? erro.response?.data.message
                                : erro.message;
                            toast.error(
                              `Falha ao Enviar E-mail. Motivo: ${msg}`
                            );
                            fecharModalPreCadastroPaciente();
                          });
                      } else {
                        fecharModalPreCadastroPaciente();
                      }
                    })
                    .catch((erro) => {
                      setLoading(false);
                      setTextoLoading("");
                      const msg =
                        erro.response?.data.message !== undefined
                          ? erro.response?.data.message
                          : erro.message;
                      toast.error(`Falha ao Enviar E-mail. Motivo: ${msg}`);
                    });
                });
            } else {
              fecharModalPreCadastroPaciente();
            }
          })
          .catch((erro) => {
            setLoading(false);
            const msg =
              erro.response?.data.message !== undefined
                ? erro.response?.data.message
                : erro.message;
            toast.error(`Falha ao Enviar E-mail. Motivo: ${msg}`);
          });
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message !== undefined
            ? erro.response?.data.message
            : erro.message;
        setLoading(false);
        setTextoLoading("");
        toast.error(msg);
      });
  };

  //#endregion

  const gravarPreCadastroPaciente = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    gravarPaciente(
      id_clinica,
      preCadastroCPF.replace(/[^0-9]/g, ""),
      preCadastroNome,
      preCadastroEmail,
      preCadastroTelefone.replace(/[^0-9]/g, ""),
      preCadastroCelular.replace(/[^0-9]/g, ""),
      getSessionData("DadosUsuario") || ""
    );
  };

  const fecharModalPreCadastroPaciente = () => {
    setAbrirModalPaciente(false);
    setPreCadastroCPF("");
    setPreCadastroNome("");
    setPreCadastroEmail("");
    setPreCadastroTelefone("");
    setPreCadastroCelular("");
  };

  useEffect(function () {


    if (tipo != "" && faturado != "") {
      buscarProcedimentos(
        getSessionData("DadosIdClinica")!,
        configuracaoAgenda.id_profissional,
        tipo
      );
    }
  }, [faturado, convenio])

  const selecionaTipoConsulta = (convenio: string) => {
    if (convenio === "") {
      setFaturado("");
      setConvenio("");
      setDescConvenio(" ");
      setDadosConvenio([]);
    } else if (convenio === "0" || convenio === "2") {
      setFaturado(convenio);
      setConvenio("");
      setDescConvenio(" ");
      setDadosConvenio([]);
    } else {
      setFaturado(convenio);
      setConvenio("");
      setDescConvenio(" ");
    }
    // console.log("convenio: ", convenio);
  };

  function valorFormatado(valor: number): string {
    return new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(valor);
  }
  // const valorNovo = valorFormatado(valor);
  // console.log("valorNovo: ", valorNovo);


  useEffect(function () {

    if (tipoEvento == '1002') {

      //-puxadinho referente a tabela codsistema
      setTabela('1002')
      selecionaTipoConsulta('4')
      selecionaTipoAtendimento('3')

      if (getSessionData("DadosIdClinica") == '2') {

        //-puxadinho de paciente e procedimento
        setIdPaciente('3553')
        setTipoProcedimento('34')
      }
      else if (getSessionData("DadosIdClinica") == '9') {

        setIdPaciente('3546')
        setTipoProcedimento('27')
      }
      else if (getSessionData("DadosIdClinica") == '11') {

        setIdPaciente('3547')
        setTipoProcedimento('28')
      }
      else if (getSessionData("DadosIdClinica") == '12') {

        setIdPaciente('3548')
        setTipoProcedimento('29')
      }
    }
  }, [tipoEvento])


  //verifica permissões

  const token_ = getSessionData("MultClinicaWebToken")
  const isSuper = getSessionData("isPerfilSuper") || "";
  const [visualizar, set_visualizar] = useState(false)
  const [criar, set_criar] = useState(false)
  const [editar_consulta, set_editar] = useState(false)
  const [excluir_consulta, set_excluir] = useState(false)
  function verificaPermissoes(id_perfil: any) {

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/perfil/carregar/${id_perfil}`, {
      headers: {
        Authorization: token_
      }
    }).then(function (resposta) {

      set_visualizar(resposta.data[0].visualizarconsultas === "1" || isSuper === "1" ? true : false)
      set_criar(resposta.data[0].criarconsultas === "1" || isSuper === "1" ? true : false)
      set_editar(resposta.data[0].editarconsultas === "1" || isSuper === "1" ? true : false)
      set_excluir(resposta.data[0].excluirconsultas === "1" || isSuper === "1" ? true : false)
    }).catch(function (erro) {

      toast.error("Erro ao carregar permissão.")
    })
  }

  //0 - > domingo, 1 -> segunda, 2 -> terça, 3 -> quarta, 4 -> quinta, 5 -> sexta, 6 -> sabado -> COMPONENTE
  //1 -> domingo, 2 -> segunda, 3 -> terça 4 -> quarta 5 -> quinta 6 -> sexta 7 -> sabado
  const [diaSemanaVer, setDiaSemanaVer] = useState<any>([])
  useEffect(function () {
    const diasDaSemana = [0, 1, 2, 3, 4, 5, 6]
    const diaAgenda = parseInt(getSessionData("DiaSemanaSala")!) - 1
    const diaAgendaMostrar = diasDaSemana.filter(function (numero: any) {
      return numero != diaAgenda
    })

    setDiaSemanaVer(diaAgendaMostrar)
    verificaPermissoes(getSessionData("DadosPerfilID"))
  }, [])

  //#region Agendamento Recorrente
  const [selectedDates, setSelectedDates] = useState<{ date: string; diaSemana: string; startTime: string; endTime: string }[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");

  const handleDateChange = (date: Date | null) => {
    if (date && startTime && endTime) {
      const dateString = date.toISOString().split('T')[0];
      const diaSemana = date.toLocaleDateString('pt-BR', { weekday: 'long' });
      let [ano, mes, dia] = dateString.split('-');
      let formattedDate = `${dia}/${mes}/${ano}`;

      const newEntry = { date: formattedDate, diaSemana, startTime, endTime };

      const alreadySelected = selectedDates.some((entry) => entry.date === newEntry.date && entry.startTime === newEntry.startTime && entry.endTime === newEntry.endTime);

      if (!alreadySelected) {
        setSelectedDates((prevDates) => [...prevDates, newEntry]);
        setStartDate(new Date());
      } else {
        toast.info("Essa data e horário já estão reservados");
      }
    } else {
      toast.info("Por favor, selecione um horário.");
    }
  };

  const handleRemoveDate = (dateToRemove: string, startTimeToRemove: string, endTimeToRemove: string) => {
    setSelectedDates((prevDates) =>
      prevDates.filter((entry) => entry.date !== dateToRemove || entry.startTime !== startTimeToRemove || entry.endTime !== endTimeToRemove)
    );
  };

  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo
          titulo={`Agendamento de Consultas - Sala: ${configuracaoAgenda.sala} - Profissional: ${configuracaoAgenda.nome}`}
        />
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <Stack direction="row" spacing={2}>
                      <Button
                        type="button"
                        className="btn btn-light text-info-emphasis bg-white border-0"
                        descricaoBotao="Voltar"
                        icone={mdiKeyboardReturn}
                        onclick={() =>
                          navigate("/agendamento/salasatendimento/")
                        }
                      />
                    </Stack>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <FullCalendar
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                        listPlugin,
                      ]}
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                      }}
                      buttonText={{
                        today: "Hoje",
                        month: "Mês",
                        week: "Semana",
                        day: "Dia",
                        list: "Agenda",
                      }}
                      initialView="timeGridWeek"
                      editable={true}
                      selectable={true}
                      selectMirror={true}
                      dayMaxEvents={true}
                      events={eventoSala as any}
                      select={selecionarHorario}
                      timeZone="UTC"
                      locale={ptbrLocale}
                      firstDay={0}
                      slotLabelFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        omitZeroMinute: false,
                      }}
                      slotDuration={configuracaoAgenda.duracao_janela}
                      slotLabelInterval={configuracaoAgenda.duracao_janela}
                      eventContent={(dadosEvento) => (
                        <EventContent
                          timeText={dadosEvento.timeText}
                          eventTitle={dadosEvento.event.title}
                          subtitle={dadosEvento.event.extendedProps.subtitle}
                          detail={dadosEvento.event.extendedProps.detail}
                        />
                      )}
                      contentHeight="auto"
                      slotMinTime={configuracaoAgenda.inicio}
                      slotMaxTime={configuracaoAgenda.fim}
                      allDaySlot={false}
                      eventClick={(dadosEvento) => editarEvento(dadosEvento)}
                      eventDrop={eventoArrastar}
                      eventResize={eventoArrastar}
                      hiddenDays={diaSemanaVer}//0 - > domingo 1 -> segunda 2 -> terça 3 -> quarta, 4 -> quinta, 5 -> sexta, 6 -> sabdo
                    // eventMouseEnter={handleEventMouseEnter} // Este é o callback para quando o cursor entra no evento
                    // eventMouseLeave={handleEventMouseLeave}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        show={abrirmodal} //event={selectedEvent}
        size="xl"
        onShow={() => {
          setSelectedDates([])
          setStartDate(new Date())
        }}
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Adicionar Evento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={gravarAgendamento}>
          <Modal.Body className="bg-white">
            <Row>
              <Col sm={4}>
                <SelectInput
                  value={tipoEvento}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setTipoEvento(e.target.value)
                  }
                  options={dadosTipoEvento}
                  placeholder="Tipo de Evento"
                  required
                />
              </Col>
              <Col sm={4}>
                <SelectInput
                  value={faturado}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    selecionaTipoConsulta(e.target.value)
                  }
                  options={dadosTipoConsulta}
                  placeholder="Tipo de Consulta"
                  disabed={tipoEvento === "1002" ? true : false}
                  required
                />
              </Col>
              <Col sm={4}>
                <label htmlFor="Convenio" className="form-label">
                  Convênio
                </label>
                <DatalistInput
                  placeholder="Digite 3 caracteres para filtrar..."
                  label="Convênio"
                  showLabel={false}
                  id="Convenio"
                  onChange={(item: any) => buscarConvenio(item)}
                  onSelect={(item: any) => setConvenio(item.id)}
                  items={dadosConvenio}
                  className="form-control bordasInferiorInput text-black text-capitalize"
                  inputProps={{
                    title: "Preenchimento obrigatório.",
                    required: tipoEvento === "1002" ? false : true,
                    disabled:
                      tipoEvento === "1002" ||
                        faturado === "0" ||
                        faturado === "2" ||
                        !faturado
                        ? true
                        : false,
                  }}
                  value={
                    tipoEvento === "1002" ||
                      faturado === "0" ||
                      faturado === "2" ||
                      !faturado
                      ? descConvenio
                      : ""
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={7}>
                <InputSemBorda
                  type="text"
                  label="Sala"
                  name="sala"
                  id="sala"
                  value={configuracaoAgenda.sala}
                  onChange={() => { }}
                  readonly
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                />
              </Col>
              <Col sm={5}>
                <InputSemBorda
                  type="text"
                  label="Tipo Sala"
                  name="tiposala"
                  id="tiposala"
                  value={configuracaoAgenda.desc_tiposala}
                  onChange={() => { }}
                  readonly
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <InputSemBorda
                  type="text"
                  label="Profissional"
                  name="profissional"
                  id="profissional"
                  value={configuracaoAgenda.nome}
                  onChange={() => { }}
                  readonly
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                />
              </Col>
              <Col sm={6} className="d-flex align-items-center">
                <label htmlFor="paciente" className="form-label">
                  Paciente
                </label>
                <DatalistInput
                  placeholder="Digite 3 caracteres para filtrar..."
                  label="Paciente"
                  showLabel={false}
                  id="paciente"
                  onChange={(item: any) => buscarPaciente(item)}
                  onSelect={(item: any) => setIdPaciente(item.id)}
                  items={dadosPaciente}
                  className="form-control bordasInferiorInput text-black text-capitalize"
                  inputProps={{
                    title: "Preenchimento obrigatório.",
                    required: tipoEvento === "1002" ? false : true,
                    disabled: tipoEvento === "1002" ? true : false,
                  }}
                  value={inputValue || ""}
                />
                <Button
                  type="button"
                  className="btn btn-light text-bg-dark bg-white border-0 ml-2"
                  descricaoBotao=""
                  icone={mdiAccountPlusOutline}
                  title="Adicionar Paciente"
                  onclick={() => setAbrirModalPaciente(true)}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={3}>
                <InputSemBorda
                  type="date"
                  label="Data Início"
                  name="dataInicio"
                  id="dataInicio"
                  value={dataInicio}
                  onChange={setDataInicio}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  type="time"
                  label="Hora Início"
                  name="horaInicio"
                  id="horaInicio"
                  value={horaInicio}
                  onChange={setHoraInicio}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  type="date"
                  label="Data Fim"
                  name="datafim"
                  id="dataFim"
                  value={dataFim}
                  onChange={setDataFim}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  type="time"
                  label="Hora Fim"
                  name="horaFim"
                  id="horaFim"
                  value={horaFim}
                  onChange={setHoraFim}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <SelectInput
                  value={tipo}
                  onChange={function (e: any) {
                    selecionaTipoAtendimento(e.target.value)
                  }}
                  options={dadosTipoAtendimento}
                  placeholder="Tipo de Agendamento"
                  required
                  disabed={tipoEvento === "1002" ? true : false}
                />
              </Col>
              <Col sm={6}>
                <SelectInput
                  value={tipoProcedimento}
                  onChange={function (e: any) {
                    selecionaProcedimento(e.target.value)
                  }}
                  options={dadosProcedimentos}
                  placeholder="Procedimento"
                  required
                  disabed={
                    tipoEvento === "1002" || ativaProcedimentos ? true : false
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SelectInput
                  value={tabela}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setTabela(e.target.value)
                  }
                  options={dadosTabelaTuss}
                  placeholder="Código TUSS"
                  required
                  disabed={tipoEvento === "1002" ? true : false}
                />
              </Col>
            </Row>

            <div className="container mt-3 mb-4">
              <fieldset className="p-2">
                <legend className="w-auto px-2">Agendamento Recorrente</legend>
                <Row>
                  <Col sm={6}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        handleDateChange(date);
                      }}
                      inline
                      dateFormat="yyyy-MM-dd"
                    />
                    <Row className="mt-2">
                      <Col>
                        <input
                          type="time"
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                          className="form-control"
                          placeholder="Hora Início"
                        />
                      </Col>
                      <Col>
                        <input
                          type="time"
                          value={endTime}
                          onChange={(e) => setEndTime(e.target.value)}
                          className="form-control"
                          placeholder="Hora Fim"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={6}>
                    <h5>Datas Selecionadas:</h5>
                    <ul>
                      {selectedDates.map((entry, index) => (
                        <li key={index} className="mt-1">
                          {entry.date} das {entry.startTime} às {entry.endTime}
                          <BootstrapButton
                            variant="danger"
                            onClick={() => handleRemoveDate(entry.date, entry.startTime, entry.endTime)}
                            style={{ marginLeft: '10px' }}
                          >
                            Remover
                          </BootstrapButton>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </fieldset>
            </div>


            <Row>
              <Col sm={3}>
                <TextField
                  label="Valor"
                  type="text"
                  value={valorFormatado(valor)}
                  onChange={(e) => setValor(parseFloat(e.target.value))}
                  variant="standard"
                  InputProps={{
                    disabled: true,
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  className="text-black"
                />
              </Col>
              <Col sm={4} style={{ display: "flex", flexDirection: "row" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Desconto:
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={tipo_Desconto}
                  name="radio-buttons-group"
                  onChange={handleTipoDescontoChange}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value={descontoR}
                    control={<Radio />}
                    label="Valor (R$)"
                    disabled={permiteDesconto || faturado === "1"}
                    style={{ marginRight: "10px" }} // Adiciona margem direita para separar os botões
                  />
                  <FormControlLabel
                    value={descontoP}
                    control={<Radio />}
                    disabled={permiteDesconto || faturado === "1"}
                    label="Percentual (%)"
                  />
                </RadioGroup>
              </Col>
              <Col sm={2}>
                <TextField
                  label="Desconto"
                  type="number"
                  value={desconto}
                  onChange={handleDescontoChange}
                  variant="standard"
                  onFocus={(e) => e.target.select()}
                  InputProps={{
                    disabled: permiteDesconto || faturado === "1",
                    startAdornment: (
                      <InputAdornment
                        position={tipo_Desconto === "R" ? "start" : "end"}
                      >
                        {tipo_Desconto === "R" ? `R$` : `% `}
                      </InputAdornment>
                    ),
                  }}
                  className="text-black"
                />
              </Col>
              <Col sm={3}>
                <TextField
                  label="Valor Final"
                  type="text"
                  disabled
                  value={valorFormatado(valorFinal)}
                  onChange={(e) => handleValorChange(e.target.value)}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  className="text-black"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputSemBorda
                  type="text"
                  label="Observação"
                  name="obs"
                  id="obs"
                  value={obs}
                  onChange={setObs}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={fecharModalAgendamento}
              />
              <Button
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* MODAL PARA EDITAR UM AGENDAMENTO */}
      <Modal
        show={abrirModalEditar}
        onHide={() => setAbrirModalEditar(false)}
        size="xl"
        onShow={() => {
          setSelectedDates([])
          setStartDate(new Date())
        }}
      >
        <Modal.Header className="bg-white" closeButton>
          <Button
            type="button"
            className="btn btn-light text-danger bg-white border-0"
            descricaoBotao=""
            title="Excluir Evento"
            icone={mdiTrashCan}
            onclick={excluirAgendamento}
          />
          <Modal.Title>Editar Evento</Modal.Title>
        </Modal.Header>
        <Form onSubmit={editarAgendamento}>
          <Modal.Body className="bg-white">
            <Row>
              <Col sm={4}>
                <SelectInput
                  value={tipoEvento}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setTipoEvento(e.target.value)
                  }
                  options={dadosTipoEvento}
                  placeholder="Tipo de Evento"
                  required
                  disabed
                />
              </Col>
              <Col sm={4}>
                <SelectInput
                  value={faturado}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    selecionaTipoConsulta(e.target.value)
                  }
                  options={dadosTipoConsulta}
                  placeholder="Tipo de Consulta"
                  disabed
                  required
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  type="text"
                  label="Sala"
                  name="sala"
                  id="sala"
                  value={descConvenio}
                  onChange={() => { }}
                  readonly
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={7}>
                <InputSemBorda
                  type="text"
                  label="Sala"
                  name="sala"
                  id="sala"
                  value={configuracaoAgenda.sala}
                  onChange={() => { }}
                  readonly
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                />
              </Col>
              <Col sm={5}>
                <InputSemBorda
                  type="text"
                  label="Tipo Sala"
                  name="tiposala"
                  id="tiposala"
                  value={configuracaoAgenda.desc_tiposala}
                  onChange={() => { }}
                  readonly
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <InputSemBorda
                  type="text"
                  label="Profissional"
                  name="profissional"
                  id="profissional"
                  value={configuracaoAgenda.nome}
                  onChange={() => { }}
                  readonly
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                />
              </Col>
              <Col sm={6}>
                <InputSemBorda
                  type="text"
                  label="Paciente"
                  name="paciente"
                  id="paciente"
                  value={paciente}
                  onChange={() => { }}
                  readonly
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-captalize"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <InputSemBorda
                  type="date"
                  label="Data Início"
                  name="dataInicio"
                  id="dataInicio"
                  value={dataInicio}
                  onChange={setDataInicio}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  type="time"
                  label="Hora Início"
                  name="horaInicio"
                  id="horaInicio"
                  value={horaInicio}
                  onChange={setHoraInicio}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  type="date"
                  label="Data Fim"
                  name="datafim"
                  id="dataFim"
                  value={dataFim}
                  onChange={setDataFim}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                />
              </Col>
              <Col sm={3}>
                <InputSemBorda
                  type="time"
                  label="Hora Fim"
                  name="horaFim"
                  id="horaFim"
                  value={horaFim}
                  onChange={setHoraFim}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lowercase"
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <SelectInput
                  value={tipo}
                  onChange={selecionaTipoAtendimento}
                  options={dadosTipoAtendimento}
                  placeholder="Tipo de Agendamento"
                  required
                  disabed={tipoEvento === "1002" ? true : false}
                />
              </Col>
              {/*<Col sm={6}>
                <SelectInput
                  value={tipoProcedimento}
                  onChange={selecionaProcedimento}
                  options={dadosProcedimentos}
                  placeholder="Procedimento"
                  required
                  disabed={
                    tipoEvento === "1002" || ativaProcedimentos ? true : false
                  }
                />
              </Col> */}
            </Row>
            <Row>
              <Col>
                <SelectInput
                  value={tabela}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    setTabela(e.target.value)
                  }
                  options={dadosTabelaTuss}
                  placeholder="Código TUSS"
                  required
                  disabed={tipoEvento === "1002" ? true : false}
                />
              </Col>
            </Row>

            {/* <div className="container mt-3 mb-4">
              <fieldset className="p-2">
                <legend className="w-auto px-2">Agendamento Recorrente</legend>
                <Row>
                  <Col sm={6}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        handleDateChange(date);
                      }}
                      inline
                      dateFormat="yyyy-MM-dd"
                    />
                    <input
                      type="time"
                      value={selectedTime}
                      onChange={(e) => setSelectedTime(e.target.value)}
                      className="form-control mt-2"
                    />
                  </Col>
                  <Col sm={6}>
                    <h5>Datas Selecionadas:</h5>
                    <ul>
                      {selectedDates.map((entry, index) => (
                        <li key={index} className="mt-1">
                          {entry.date} às {entry.time}
                          <BootstrapButton
                            variant="danger"
                            onClick={() => handleRemoveDate(entry.date, entry.time)}
                            style={{ marginLeft: '10px' }}
                          >
                            Remover
                          </BootstrapButton>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </fieldset>
            </div> */}


            <Row>
              <Col sm={3}>
                <TextField
                  label="Valor"
                  type="text"
                  value={valorFormatado(valor)}
                  onChange={(e) => setValor(parseFloat(e.target.value || "0"))}
                  variant="standard"
                  InputProps={{
                    disabled: true,
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  className="text-black"
                />
              </Col>
              <Col sm={4} style={{ display: "flex", flexDirection: "row" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Desconto:
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={tipo_Desconto}
                  name="radio-buttons-group"
                  onChange={handleTipoDescontoChange}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <FormControlLabel
                    value={descontoR}
                    control={<Radio />}
                    label="Valor (R$)"
                    disabled={permiteDesconto}
                    style={{ marginRight: "10px" }} // Adiciona margem direita para separar os botões
                  />
                  <FormControlLabel
                    value={descontoP}
                    control={<Radio />}
                    disabled={permiteDesconto}
                    label="Percentual (%)"
                  />
                </RadioGroup>
              </Col>
              <Col sm={2}>
                <TextField
                  label="Desconto"
                  type="number"
                  value={desconto}
                  onChange={handleDescontoChange}
                  variant="standard"
                  onFocus={(e) => e.target.select()}
                  InputProps={{
                    disabled: permiteDesconto,
                    startAdornment: (
                      <InputAdornment
                        position={tipo_Desconto === "R" ? "start" : "end"}
                      >
                        {tipo_Desconto === "R" ? `R$` : `% `}
                      </InputAdornment>
                    ),
                  }}
                  className="text-black"
                />
              </Col>
              <Col sm={3}>
                <TextField
                  label="Valor Final"
                  type="text"
                  disabled
                  value={valorFormatado(valorFinal)}
                  onChange={(e) => handleValorChange(e.target.value)}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  className="text-black"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <InputSemBorda
                  type="text"
                  label="Observação"
                  name="obs"
                  id="obs"
                  value={obs}
                  onChange={setObs}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => setAbrirModalEditar(false)}
              />
              <Button
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* MODAL PARA ADICIONAR PACIENTE */}
      <Modal
        show={abrirModalPaciente}
        // onHide={() => setAbrirModalPaciente(false)}
        keyboard={false}
        size="xl"
        className="bg-secondary-lighten"
        centered
        backdrop="static"
      >
        <Modal.Header className="bg-white">
          <Modal.Title>Adicionar Paciente - Pré Cadastro</Modal.Title>
        </Modal.Header>
        <Form onSubmit={gravarPreCadastroPaciente}>
          <Modal.Body className="bg-white">
            <Row>
              <Col sm={4}>
                <InputSemBorda
                  type="text"
                  label="CPF"
                  name="cpf"
                  id="cpf"
                  value={mascaraCPF(preCadastroCPF)}
                  onChange={setPreCadastroCPF}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
              <Col sm={8}>
                <InputSemBorda
                  type="text"
                  label="Nome"
                  name="nome"
                  id="nome"
                  value={preCadastroNome}
                  onChange={setPreCadastroNome}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <InputSemBorda
                  type="email"
                  label="Email"
                  name="email"
                  id="email"
                  value={preCadastroEmail}
                  onChange={setPreCadastroEmail}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-lower"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  type="text"
                  label="Celular"
                  name="celular"
                  id="celular"
                  value={mascaraTelefoneCelular(preCadastroCelular)}
                  onChange={setPreCadastroCelular}
                  required
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
              <Col sm={4}>
                <InputSemBorda
                  type="text"
                  label="Telefone"
                  name="telefone"
                  id="telefone"
                  value={mascaraTelefoneFixo(preCadastroTelefone)}
                  onChange={setPreCadastroTelefone}
                  classNameInputsSemBorda="form-control bordasInferiorInput text-black text-capitalize"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="bg-white">
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={fecharModalPreCadastroPaciente}
              />
              <Button
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <ModalLoading show={loading} label={textoLoading} />
    </>
  );
}
